<template>
    <div>
        <b-card class="text-black">
            <b-form>
                <b-row class="mb-1" align-v="center">
                    <b-col md="3">
                        <b-form-group label="Name" label-for="name">
                            <template #label>
                                <h6 class="font-weight-bold">Name</h6>
                            </template>

                            <b-form-input id="name"
                                          v-model="name"
                                          placeholder="Search name" />

                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Username" label-for="username">
                            <template #label>
                                <h6 class="font-weight-bold">Username</h6>
                            </template>

                            <b-form-input id="cnic"
                                          v-model="cnic"
                                          placeholder="Search Username" />

                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Mobile" label-for="mobile">
                            <template #label>
                                <h6 class="font-weight-bold">Mobile Number</h6>
                            </template>

                            <b-form-input id="mobile"
                                          v-model="mobile"
                                          placeholder="Search Mobile No" />

                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group>
                            <template #label>
                                <h6 class="font-weight-bold">User Type</h6>
                            </template>
                            <v-select id="user_type"
                                      v-model="user_type"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="user_types"
                                      placeholder="Select User Type"
                                      label="name"
                                      class="text-black" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group>
                            <template #label>
                                <h6 class="font-weight-bold">Role</h6>
                            </template>
                            <v-select id="role"
                                      v-model="role"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="roles"
                                      placeholder="Select Role"
                                      label="name"
                                      class="text-black" />
                        </b-form-group>
                    </b-col>
                    <template v-if="role && role.name.includes('Mess')">
                        <b-col md="3">
                            <b-form-group>
                                <template #label>
                                    <h6 class="font-weight-bold">City</h6>
                                </template>
                                <v-select id="city"
                                        v-model="city"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="cities"
                                        placeholder="Select City"
                                        label="name"
                                        class="text-black" />
                            </b-form-group>
                        </b-col>
                    </template>
                </b-row>
                <b-row>
                    <b-col class="text-right mb-1">
                        <b-button variant="info" pill @click="clearFilter()" class="mr-2">
                            Clear Search
                        </b-button>
                        <b-button variant="primary"
                                  pill
                                  style="margin-right: 2%"
                                  @click="searchUsersData">
                            <feather-icon icon="SearchIcon" class="mr-50" />
                            Search
                        </b-button>
                        <b-button variant="primary" pill @click="createUser"
                        v-if="hasPermission('booking_user_create')">
                            <feather-icon icon="PlusIcon" class="mr-50" />
                            <span class="align-middle">Create</span>
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            <b-table responsive
                     :fields="fields"
                     sticky-header="1500px"
                     :items="users"
                     :paginated="true"
                     details-td-class="m-0 p-0"
                     small>
                <template #cell(user_type)="row">
                    {{ getUserTypeValue(row.item.user_type)}}
                </template>
                <template #cell(manage)="row">
                    <b-button variant="info"
                              pill
                              size="sm"
                              class="mr-50"
                              @click="editUser(row.item)"
                              v-if="hasPermission('booking_user_update')">
                        Change Mobile Number
                    </b-button>
                    <b-button variant="success"
                              pill
                              size="sm"
                              class="mr-50"
                              @click="editName(row.item)"
                              v-if="hasPermission('booking_user_update')">
                        Change Name
                    </b-button>
                </template>
            </b-table>
            <b-row>
                <b-col md="10">
                    <b-pagination :total-rows="totalDataLength"
                                  :per-page="per_page"
                                  align="center"
                                  v-model="currentPage" />
                </b-col>
                <b-col md="2">
                    <div style="float:left">
                        <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
                        <h5 style="margin: 0; display: inline;" align="right">
                            <strong>
                                {{totalDataLength}}
                            </strong>
                        </h5>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <CreateBookingUser @modalClosed="onModalClosed" />
        <EditBookingUser 
        :user="user"
         @modalClosed="onModalClosed" 
         :key="`edit-${editUserModalCount}`"
         />
        <EditBookingUserName 
        :user="user"
        @modalClosed="onModalClosed"
        :key="`name-${editUserNameModalCount}`"
        />
        <b-overlay :show="show"
                   spinner-variant="primary"
                   no-wrap />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import CreateBookingUser from "@/components/booking/admin/CreateBookingUser.vue";
    import EditBookingUser from "@/components/booking/admin/EditBookingUser.vue";
    import EditBookingUserName from "@/components/booking/admin/EditBookingUserName.vue";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import { required } from "@validations";
    import util from "@/util.js";
    import DatePicker from "vue2-datepicker";
    import "vue2-datepicker/index.css";
    export default {
        components: {
            DatePicker,
            ValidationProvider,
            ValidationObserver,
            required,
            CreateBookingUser,
            EditBookingUser,
            EditBookingUserName,
        },
        data() {
            return {
                fields: [
                    { key: "name", label: "Name" },
                    { key: "cnic", label: "Username" },
                    { key: "mobile", label: "Mobile" },
                    { key: "role_data.name", label: "Role" },
                    { key: "user_type", label: "User Type" },
                    { key: "manage", label: "Manage" },
                ],
                currentPage: 1,
                totalDataLength: 0,
                per_page: 0,
                users: [],
                user: null,
                editUserModalCount: 0,
                editUserNameModalCount: 0,
                cnic: null,
                user_types: [
                    { name: "SERVING", value: 1 },
                    { name: "RETIRED", value: 2 },
                    { name: "CIVILIAN", value: 3 },],
                user_type: null,
                name: null,
                role: null,
                roles: [],
                show: false,
                mobile: '',
                cities: [],
                city: null,
            };
        },
        mixins: [util],
        async mounted() {
            try {
                let res = await this.getBookingUnpaginatedRoles({
                    only_booking_roles: true
                })
                this.roles = res.data;
                res = await this.getBookingCitiesUnpaginated({})
                this.cities = res.data
                await this.searchUsersData();

            } catch (error) {
                this.displayError(error);
            }
        },
        methods: {
            ...mapActions({
                updateUser: "appData/updateUser",
                getBookingUsers: "appData/getBookingUsers",
                getBookingUnpaginatedRoles: "appData/getBookingUnpaginatedRoles",
                getBookingCitiesUnpaginated: "appData/getBookingCitiesUnpaginated",
            }),
            async searchUsersData() {
                try {
                    this.show = true;
                    let tempParams = {
                        pageNumber: this.currentPage,
                    }
                    if(this.name){
                        tempParams['name'] = this.name
                    }
                    if(this.cnic){
                        tempParams['cnic'] = this.cnic
                    }
                    if(this.mobile){
                        tempParams['mobile'] = this.mobile
                    }
                    if(this.user_type){
                        tempParams['user_type'] = this.user_type.value
                    }
                    if(this.role){
                        tempParams['role'] = this.role.id
                    }
                    if(this.city){
                        tempParams['city'] = this.city.id
                    }
                    const res = await this.getBookingUsers(tempParams)
                    this.users = res.data.results;
                    this.per_page = res.data.per_page;
                    this.totalDataLength = res.data.count;
                    this.totalPageCount = res.data.total_pages;
                    this.show = false;
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },
            async clearFilter() {
                this.name = '';
                this.cnic = '';
                this.mobile = '';
                this.mobile = '';
                this.role = null;
                await this.searchUsersData();
            },
            createUser() {
                this.$nextTick(() => {
                    this.$bvModal.show("create-booking-user-modal");
                });
            },
            editUser(user) {
                this.user = user;
                this.editUserModalCount += 1;
                this.$nextTick(() => {
                    this.$bvModal.show("edit-booking-user-modal");
                });
            },
            editName(user) {
                this.user = user;
                this.editUserNameModalCount += 1;
                this.$nextTick(() => {
                    this.$bvModal.show("edit-user-name-modal");
                });
            },
            // async removeUser(user) {
            //     this.user = user;
            //     this.show = true;
            //     try {
            //         this.$swal({
            //             title: "Are you sure to want delete this user?",
            //             icon: "warning",
            //             showCancelButton: true,
            //             confirmButtonText: "Yes, Delete!",
            //             customClass: {
            //                 confirmButton: "btn btn-primary",
            //                 cancelButton: "btn btn-outline-primary ml-1",
            //             },
            //             buttonsStyling: false,
            //         }).then(async (results) => {
            //             if (results.value) {
            //                 const res = await this.deleteUser({
            //                     pk: this.user.id,
            //                 });
            //                 if (res.status === 200) {
            //                     await this.searchUsersData();
            //                     this.$swal({
            //                         title: "User deleted successfully",
            //                         icon: "success",
            //                     });
            //                 }
            //             }
            //         });
            //         this.show = false;
            //     } catch (error) {
            //         this.show = false;
            //         this.displayError(error);
            //     }
            // },
            async onModalClosed() {
                await this.searchUsersData();
            },
        },
        computed: {
            ...mapGetters({
                hasPermission: "appData/hasPermission",
            }),
        },
        watch: {
            currentPage: {
                handler: async function (value) {
                    await this.searchUsersData();
                },
            },
            async role(newValue, oldValue) {
                this.city = null
            }
        },
    };
</script>

<style>
    .text-black {
        color: black !important;
        /* font-size: 1.2rem; */
    }
</style>
