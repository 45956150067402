<template>
  <b-modal
    id="edit-booking-user-modal"
    centered
    size="md"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    dialog-class="my-dialog-class"
  >
    <template #modal-title> <h2 class="m-0">Change Mobile Number</h2> </template>
    <validation-observer ref="editBookingUserFormValidation">
      <b-form @submit.prevent>
        <b-form-row>
          <b-col md="12">
            <b-form-group label-for="mobile">
              <template #label>
                Mobile <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mobile"
                rules="required|integer|length:11"
              >
                <b-form-input
                  id="mobile"
                  v-model="mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-form-group class="text-right">
        <b-button type="submit" variant="primary" pill @click="validationForm">
          Submit
        </b-button>
      </b-form-group>
    </template>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util, togglePasswordVisibility],
    props: {
      user: Object,
  },
  //  props: ["user"],
  data() {
    return {
      show : false,
      required,
      password: "",
      name:"",
      username: "",
      mobile: "",
      role: null,
      roles: [],
    };
  },
  async mounted() {
    if(this.user)
    {
      this.mobile = this.user.mobile;
    }
  },
  methods: {
    ...mapActions({
      createUser: "appData/createBookingUser",
      getBookingUnpaginatedRoles: "appData/getBookingUnpaginatedRoles",
      updateUser: "appData/updateUser",
    }),
    async validationForm() {
      try {
        const success = await this.$refs.editBookingUserFormValidation.validate();
        if (success) {
          await this.submit();
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
    },
    async submit() {
        this.show = true;

      try {
        const res = await this.updateUser({
          payload: {
            mobile: this.mobile,
            updated_by: this.getLoggedInUser.id,
          },
          pk: this.user.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Mobile Number Updated successfully",
            icon: "success",
          });
          this.show = false;
          this.$nextTick(() => {
            this.$bvModal.hide("edit-booking-user-modal");
          });
          this.reset();
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.show = false;
        // this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
  },
};
</script>

<style></style>
